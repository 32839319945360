<!-- 定义模版对象 -->
<template>
  <div class="jp" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
    <div class="jp-top">

      <div class="jp-title">
        <div class="jp-title-color"></div>
        <div class="jp-title-page font-18">{{ pagetitle }}</div>
      </div>
      <div class="jp-choose">
        <!-- 我的店铺 -->
        <div class="jp-choose-item" @click="changeSelect(0)">
          <div class="jp-choose-item-choose" v-if="my_data && my_data.id && my_data.id > 0 ">
            <!-- 图片选择 -->
            <div class="jp-choose-item-choose-pic">
              <img class="jp-choose-item-choose-pic" :src="my_data.imageUrl || my_data.cover || require('../../assets/imgs/empty.png')" style="z-index:2">
              <img :src="type == 1 ? require('../../assets/imgs/jp_1.png') : require('../../assets/imgs/jd_1.png')" style="width: 48px;position: absolute;top:-6px;left:32px;z-index: 3;border-radius:0 5px">
              <img src="../../assets/images/sj.png" style="width: 10px;position: absolute;top:12px;left:70px;z-index: 1;">
            </div>
            <div class="jp-choose-item-choose-title font-12">
              {{my_data.name || my_data.title || ''}}
            </div>
            <div class="jp-choose-item-choose-close" @click.stop="deleteItem(0)">
              <i class="el-icon-close font-14"></i>
            </div>
          </div>
          <div class="jp-choose-item-empty" v-else>
            <i class="el-icon-plus font-16"></i>
          </div>
        </div>
        <div class="jp-choose-contain">
          <img class="jp-choose-contain-vs" src="../../assets/images/vs.png">
        </div>
        <!-- 竞品2 -->
        <div class="jp-choose-item" @click="changeSelect(1)">
          <div class="jp-choose-item-choose" v-if="first_data && first_data.id && first_data.id > 0 ">
            <!-- 图片选择 -->
            <div class="jp-choose-item-choose-pic">
              <img class="jp-choose-item-choose-pic" :src="first_data.imageUrl || first_data.cover || require('../../assets/imgs/empty.png')" style="z-index:2">
              <img :src="type == 1 ? require('../../assets/imgs/jp_2.png') : require('../../assets/imgs/jd_2.png')" style="width: 48px;position: absolute;top:-6px;left:32px;z-index: 3;border-radius:0 5px">
              <img src="../../assets/images/sj.png" style="width: 10px;position: absolute;top:12px;left:70px;z-index: 1;">
            </div>
            <div class="jp-choose-item-choose-title font-12">
              {{ first_data.name || first_data.title || ''}}
            </div>
            <div class="jp-choose-item-choose-close" @click.stop="deleteItem(1)">
              <i class="el-icon-close font-14"></i>
            </div>
          </div>
          <div class="jp-choose-item-empty" v-else>
            <i class="el-icon-plus font-16"></i>
          </div>
        </div>
        <div class="jp-choose-contain">
          <img class="jp-choose-contain-vs" src="../../assets/images/vs.png">
        </div>
        <!-- 竞品2 -->
        <div class="jp-choose-item " @click="changeSelect(2)">
          <div class="jp-choose-item-choose" v-if="secord_data && secord_data.id && secord_data.id > 0 ">
            <!-- 图片选择 -->
            <div class="jp-choose-item-choose-pic">
              <img class="jp-choose-item-choose-pic" :src="secord_data.imageUrl || secord_data.cover || require('../../assets/imgs/empty.png')" style="z-index:2">
              <img :src="type == 1 ? require('../../assets/imgs/jp_3.png') : require('../../assets/imgs/jd_3.png')" style="width: 48px;position: absolute;top:-6px;left:32px;z-index: 3;border-radius:0 5px">
              <img src="../../assets/images/sj.png" style="width: 10px;position: absolute;top:12px;left:70px;z-index: 1;">
            </div>
            <div class="jp-choose-item-choose-title font-12">
              {{ secord_data.name || secord_data.title || ''}}
            </div>
            <div class="jp-choose-item-choose-close" @click.stop="deleteItem(2)">
              <i class="el-icon-close font-14"></i>
            </div>
          </div>
          <div class="jp-choose-item-empty" v-else>
            <i class="el-icon-plus font-16"></i>
          </div>
        </div>
      </div>
      <div class="jp-gap">
        <el-divider></el-divider>
      </div>
      <div class="jp-data">
        <div class="jp-data-head">
          <div class="jp-data-head-title font-16">
            关键指标
          </div>
          <div class="jp-data-head-date">
            <div :class=" form_date_type == 1 ?  'jp-data-head-date jp-data-head-date-choose font-12' :'jp-data-head-date font-12' " @click="changeFormDate(1)">
              7日
            </div>
            <el-divider direction="vertical" style="color: #E7E7E7;"></el-divider>
            <div :class=" form_date_type == 2 ?  'jp-data-head-date jp-data-head-date-choose font-12' :'jp-data-head-date font-12' " @click="changeFormDate(2)">
              30日
            </div>
          </div>
        </div>
        <div class=" jp-data-content">
          <div class="jp-data-content-items">
            <!-- 竞品1 -->
            <div class="jp-data-content-items-item">
              <div class="jp-data-content-items-item-jiaobiao">
                <el-image :src="type == 1 ? require('../../assets/imgs/jp_1.png') :require('../../assets/imgs/jd_1.png')" style="width:44px;height:17px;border-radius:5px 0" alt=""></el-image>
              </div>
              <div class="jp-data-content-items-item-part">
                <div class="jp-data-content-items-item-part-left">
                  <img src="../../assets/imgs/dpzxl.png" style="width:42px;height:42px" alt="">
                </div>
                <div class="jp-data-content-items-item-part-right">
                  <div class="jp-data-content-items-item-part-right-title font-14">
                    商品销售量
                  </div>
                  <div class="jp-data-content-items-item-part-right-num font-16">
                    {{ ( type == 1 ? (my_data.sales >= 0 ?  my_data.sales : '暂无数据') : ( my_data.shopSold >= 0 ? my_data.shopSold : '暂无数据')) | formatMoney }}
                  </div>
                </div>
              </div>
              <div class="jp-data-content-items-item-part">
                <div class="jp-data-content-items-item-part-left">
                  <img src="../../assets/imgs/jp_sxe.png" style="width:42px;height:42px" alt="">
                </div>
                <div class="jp-data-content-items-item-part-right">
                  <div class="jp-data-content-items-item-part-right-title font-14">
                    销售额
                  </div>
                  <div class="jp-data-content-items-item-part-right-num font-16">
                    {{ ( type == 1 ? (my_data.salesAmount >= 0 ?  my_data.salesAmount : '暂无数据') : ( my_data.shopSoldAmount >= 0 ? my_data.shopSoldAmount : '暂无数据')) | formatMoney }}
                  </div>
                </div>
              </div>
              <div class="jp-data-content-items-item-part" style="margin-top:12px">
                <div class="jp-data-content-items-item-part-left">
                  <img src="../../assets/imgs/pf.png" style="width:42px;height:42px" alt="">
                </div>
                <div class="jp-data-content-items-item-part-right">
                  <div class="jp-data-content-items-item-part-right-title font-14">
                    评分
                  </div>
                  <div class="jp-data-content-items-item-part-right-num font-16">
                    {{ my_data.score >= 0 ?  ( my_data.score == 0 ? '--':my_data.score)  :  '暂无数据' }}
                  </div>
                </div>
              </div>
              <div class="jp-data-content-items-item-part" style="margin-top:12px">
                <div class="jp-data-content-items-item-part-left">
                  <img src="../../assets/imgs/dpzxl.png" style="width:42px;height:42px" alt="">
                </div>
                <div class="jp-data-content-items-item-part-right">
                  <div class="jp-data-content-items-item-part-right-title font-14">
                    平均客单价
                  </div>
                  <div class="jp-data-content-items-item-part-right-num font-16" v-if='type == 1'>
                    {{ my_data.currencySymbol  ?  my_data.currencySymbol : '暂无数据' }}{{ my_data.price ? my_data.price : ''}}
                  </div>
                  <div class="jp-data-content-items-item-part-right-num font-16" v-else>
                    {{  ( my_data.avg ? my_data.avg : '暂无数据') | formatMoney }}
                  </div>
                </div>
              </div>
            </div>

            <div class="jp-data-contain">
              <img class="jp-data-contain-vs" src="../../assets/images/vs.png">
            </div>
            <!-- 竞品2 -->
            <div class="jp-data-content-items-item">
              <div class="jp-data-content-items-item-jiaobiao">
                <el-image :src="type == 1 ? require('../../assets/imgs/jp_2.png') :require('../../assets/imgs/jd_2.png')" style="width:44px;height:17px;border-radius:5px 0" alt=""></el-image>
              </div>
              <div class="jp-data-content-items-item-part">
                <div class="jp-data-content-items-item-part-left">
                  <img src="../../assets/imgs/dpzxl.png" style="width:42px;height:42px" alt="">
                </div>
                <div class="jp-data-content-items-item-part-right">
                  <div class="jp-data-content-items-item-part-right-title font-14">
                    商品销售量
                  </div>
                  <div class="jp-data-content-items-item-part-right-num font-16">
                    {{  (type == 1 ? (first_data.sales >= 0 ?  first_data.sales : '暂无数据') : ( first_data.shopSold >=0 ? first_data.shopSold : '暂无数据')) | formatMoney }}

                  </div>
                </div>
              </div>
              <div class="jp-data-content-items-item-part">
                <div class="jp-data-content-items-item-part-left">
                  <img src="../../assets/imgs/spsl.png" style="width:42px;height:42px" alt="">
                </div>
                <div class="jp-data-content-items-item-part-right">
                  <div class="jp-data-content-items-item-part-right-title font-14">
                    销售额
                  </div>
                  <div class="jp-data-content-items-item-part-right-num font-16">
                    {{  (type == 1 ? (first_data.salesAmount >= 0 ?  first_data.salesAmount : '暂无数据') : ( first_data.shopSoldAmount >= 0 ? first_data.shopSoldAmount : '暂无数据')) | formatMoney }}
                  </div>
                </div>
              </div>
              <div class="jp-data-content-items-item-part" style="margin-top:12px">
                <div class="jp-data-content-items-item-part-left">
                  <img src="../../assets/imgs/pf.png" style="width:42px;height:42px" alt="">
                </div>
                <div class="jp-data-content-items-item-part-right">
                  <div class="jp-data-content-items-item-part-right-title font-14">
                    评分
                  </div>
                  <div class="jp-data-content-items-item-part-right-num font-16">
                    {{ first_data.score >= 0 ?  ( first_data.score == 0 ? '--':first_data.score)  :  '暂无数据' }}
                  </div>
                </div>
              </div>
              <div class="jp-data-content-items-item-part" style="margin-top:12px">
                <div class="jp-data-content-items-item-part-left">
                  <img src="../../assets/imgs/dpzxl.png" style="width:42px;height:42px" alt="">
                </div>
                <div class="jp-data-content-items-item-part-right">
                  <div class="jp-data-content-items-item-part-right-title font-14">
                    平均客单价
                  </div>
                  <div class="jp-data-content-items-item-part-right-num font-16" v-if='type == 1'>
                    {{ first_data.currencySymbol  ?  first_data.currencySymbol : '暂无数据' }}{{ first_data.price ? first_data.price : ''}}
                  </div>
                  <div class="jp-data-content-items-item-part-right-num font-16" v-else>
                    {{  ( first_data.avg ? first_data.avg : '暂无数据') | formatMoney }}
                  </div>
                </div>
              </div>
            </div>
            <div class="jp-data-contain">
              <img class="jp-data-contain-vs" src="../../assets/images/vs.png">
            </div>
            <!-- 竞品3 -->
            <div class="jp-data-content-items-item">
              <div class="jp-data-content-items-item-jiaobiao">
                <el-image :src="type == 1 ? require('../../assets/imgs/jp_3.png') :require('../../assets/imgs/jd_3.png')" style="width:44px;height:17px;border-radius:5px 0" alt=""></el-image>
              </div>
              <div class="jp-data-content-items-item-part">
                <div class="jp-data-content-items-item-part-left">
                  <img src="../../assets/imgs/dpzxl.png" style="width:42px;height:42px" alt="">
                </div>
                <div class="jp-data-content-items-item-part-right">
                  <div class="jp-data-content-items-item-part-right-title font-14">
                    商品销售量
                  </div>
                  <div class="jp-data-content-items-item-part-right-num font-16">
                    {{  (type == 1 ? (secord_data.sales >= 0 ?  secord_data.sales : '暂无数据') : ( secord_data.shopSold >= 0 ? secord_data.shopSold : '暂无数据')) | formatMoney }}

                  </div>
                </div>
              </div>
              <div class="jp-data-content-items-item-part">
                <div class="jp-data-content-items-item-part-left">
                  <img src="../../assets/imgs/spsl.png" style="width:42px;height:42px" alt="">
                </div>
                <div class="jp-data-content-items-item-part-right">
                  <div class="jp-data-content-items-item-part-right-title font-14">
                    销售额
                  </div>
                  <div class="jp-data-content-items-item-part-right-num font-16">
                    {{  type == 1 ? (secord_data.saleAmount ?  secord_data.saleAmount : '暂无数据') : ( secord_data.shopSoldAmount ? secord_data.shopSoldAmount : '暂无数据') | formatMoney }}
                  </div>
                </div>
              </div>
              <div class="jp-data-content-items-item-part" style="margin-top:12px">
                <div class="jp-data-content-items-item-part-left">
                  <img src="../../assets/imgs/pf.png" style="width:42px;height:42px" alt="">
                </div>
                <div class="jp-data-content-items-item-part-right">
                  <div class="jp-data-content-items-item-part-right-title font-14">
                    评分
                  </div>
                  <div class="jp-data-content-items-item-part-right-num font-16">
                    {{ secord_data.score >= 0 ?  ( secord_data.score == 0 ? '--':secord_data.score)  :  '暂无数据' }}
                  </div>
                </div>
              </div>
              <div class="jp-data-content-items-item-part" style="margin-top:12px">
                <div class="jp-data-content-items-item-part-left">
                  <img src="../../assets/imgs/dpzxl.png" style="width:42px;height:42px" alt="">
                </div>
                <div class="jp-data-content-items-item-part-right">
                  <div class="jp-data-content-items-item-part-right-title font-14">
                    平均客单价
                  </div>
                  <div class="jp-data-content-items-item-part-right-num font-16" v-if='type == 1'>
                    {{ secord_data.currencySymbol  ?  secord_data.currencySymbol : '暂无数据' }}{{ secord_data.price ? secord_data.price : ''}}
                  </div>
                  <div class="jp-data-content-items-item-part-right-num font-16" v-else>
                    {{  ( secord_data.avg ? secord_data.avg : '暂无数据') | formatMoney }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="jp-data-charts">
          <compare :bingtuData="form_data" :type="type" ref="compare"></compare>
        </div>

      </div>
    </div>
    <div class="jp-bottom">
      <!-- 竞品1 -->
      <div class="jp-bottom-danpin">
        <div class="jp-bottom-danpin-head">
          <div class="jp-bottom-danpin-head-left">
            <div class="jp-bottom-danpin-head-left-color">
            </div>
            <div class="jp-bottom-danpin-head-left-page font-18">
              {{type == 1 ? '竞品' :'竞店' }}1
            </div>
          </div>
          <div class="jp-bottom-danpin-head-right " v-if="my_data && my_data.id > 0">
            <div @click="changRankTime(0,0)" :class=" my_date == 0 ? 'jp-bottom-danpin-head-right-item jp-bottom-danpin-head-right-item-choose' : 'jp-bottom-danpin-head-right-item' ">
              昨日
            </div>
            <el-divider direction="vertical" style="color: #E7E7E7;"></el-divider>
            <div @click="changRankTime(0,1)" :class=" my_date == 1 ? 'jp-bottom-danpin-head-right-item jp-bottom-danpin-head-right-item-choose' : 'jp-bottom-danpin-head-right-item' ">
              7日
            </div>
            <el-divider direction="vertical" style="color: #E7E7E7;"></el-divider>
            <div @click="changRankTime(0,2)" :class=" my_date == 2 ? 'jp-bottom-danpin-head-right-item jp-bottom-danpin-head-right-item-choose' : 'jp-bottom-danpin-head-right-item' ">
              30日
            </div>
          </div>
        </div>
        <div :class="(my_list && my_list.length > 0) ? 'jp-bottom-danpin-content'  : 'jp-bottom-danpin-content jp-bottom-danpin-content-center '">
          <div v-if="my_list && my_list.length > 0">
            <div v-for="(item,index) in my_list " :key="index" class="jp-bottom-danpin-content-list" @click="tzxq(item.shopId)">
              <div class="jp-bottom-danpin-content-item">
                <div class="jp-bottom-danpin-content-item-img">
                  <img :src=" type == 1 ? ( my_data.imageUrl || require('../../assets/imgs/empty.png')) : ( item.imageUrl || require('../../assets/imgs/empty.png'))" class="jp-bottom-danpin-content-item-img-main" alt="">
                  <img v-if="index==0" class="jp-bottom-danpin-content-item-img-price" src="../../assets/images/Slice16.png" alt="">
                  <img v-if="index==1" class="jp-bottom-danpin-content-item-img-price" src="../../assets/images/Slice17.png" alt="">
                  <img v-if="index==2" class="jp-bottom-danpin-content-item-img-price" src="../../assets/images/Slice18.png" alt="">
                </div>
                <div class="jp-bottom-danpin-content-item-title font-14">
                  {{ (type == 1 ?  item.keyword : item.productName) || ''}}
                </div>
                <div class="jp-bottom-danpin-content-item-sell">
                  <div class="jp-bottom-danpin-content-item-sell-title font-12">
                    {{  type == 1 ? '交易指数' : '销量' }}
                  </div>
                  <div class="jp-bottom-danpin-content-item-sell-num font-18">
                    {{  (type == 1 ? item.dsr : item.sales) || 0 | formatMoney}}
                  </div>
                </div>
              </div>
              <el-divider></el-divider>
            </div>
          </div>
          <div v-else>
            <el-empty description="暂无数据"></el-empty>
          </div>
        </div>
      </div>
      <!--竞品2  -->
      <div class="jp-bottom-danpin">
        <div class="jp-bottom-danpin-head">
          <div class="jp-bottom-danpin-head-left">
            <div class="jp-bottom-danpin-head-left-color">
            </div>
            <div class="jp-bottom-danpin-head-left-page font-18">
              {{type == 1 ? '竞品' :'竞店' }}2
            </div>
          </div>
          <div class="jp-bottom-danpin-head-right" v-if="first_data && first_data.id > 0">
            <div @click="changRankTime(1,0)" :class=" first_date == 0 ? 'jp-bottom-danpin-head-right-item jp-bottom-danpin-head-right-item-choose' : 'jp-bottom-danpin-head-right-item' ">
              昨日
            </div>
            <el-divider direction="vertical" style="color: #E7E7E7;"></el-divider>
            <div @click="changRankTime(1,1)" :class=" first_date == 1 ? 'jp-bottom-danpin-head-right-item jp-bottom-danpin-head-right-item-choose' : 'jp-bottom-danpin-head-right-item' ">
              7日
            </div>
            <el-divider direction="vertical" style="color: #E7E7E7;"></el-divider>
            <div @click="changRankTime(1,2)" :class=" first_date == 2 ? 'jp-bottom-danpin-head-right-item jp-bottom-danpin-head-right-item-choose' : 'jp-bottom-danpin-head-right-item' ">
              30日
            </div>
          </div>
        </div>
        <div :class="(first_list && first_list.length > 0) ? 'jp-bottom-danpin-content'  : 'jp-bottom-danpin-content jp-bottom-danpin-content-center '">
          <div v-if="first_list && first_list.length > 0 ">
            <div v-for="(item,index) in first_list " :key="index" class="jp-bottom-danpin-content-list" @click="tzxqa(item.productId)">
              <div class="jp-bottom-danpin-content-item">
                <div class="jp-bottom-danpin-content-item-img">
                  <img :src=" type == 1 ?  ( first_data.imageUrl || require('../../assets/imgs/empty.png')) : ( item.imageUrl || require('../../assets/imgs/empty.png'))" class="jp-bottom-danpin-content-item-img-main" alt="">
                  <img v-if="index==0" class="jp-bottom-danpin-content-item-img-price" src="../../assets/images/Slice16.png" alt="">
                  <img v-if="index==1" class="jp-bottom-danpin-content-item-img-price" src="../../assets/images/Slice17.png" alt="">
                  <img v-if="index==2" class="jp-bottom-danpin-content-item-img-price" src="../../assets/images/Slice18.png" alt="">
                </div>
                <div class="jp-bottom-danpin-content-item-title font-14">
                  {{ (type == 1 ?  item.keyword : item.productName) || ''}}
                </div>
                <div class="jp-bottom-danpin-content-item-sell">
                  <div class="jp-bottom-danpin-content-item-sell-title font-12">
                    {{  type == 1 ? '交易指数' : '销量' }}
                  </div>
                  <div class="jp-bottom-danpin-content-item-sell-num font-18">
                    {{  (type == 1 ? item.dsr : item.sales) || 0 | formatMoney}}
                  </div>
                </div>
              </div>
              <el-divider></el-divider>
            </div>
          </div>
          <div v-else>
            <el-empty description="暂无数据"></el-empty>
          </div>

        </div>
      </div>
      <!--竞品3  -->
      <div class="jp-bottom-danpin">
        <div class="jp-bottom-danpin-head">
          <div class="jp-bottom-danpin-head-left">
            <div class="jp-bottom-danpin-head-left-color">
            </div>
            <div class="jp-bottom-danpin-head-left-page font-18">
              {{type == 1 ? '竞品' :'竞店' }}3
            </div>
          </div>
          <div class="jp-bottom-danpin-head-right" v-if="secord_data && secord_data.id > 0">
            <div @click="changRankTime(2,0)" :class=" secord_date == 0 ? 'jp-bottom-danpin-head-right-item jp-bottom-danpin-head-right-item-choose' : 'jp-bottom-danpin-head-right-item' ">
              昨日
            </div>
            <el-divider direction="vertical" style="color: #E7E7E7;"></el-divider>
            <div @click="changRankTime(2,1)" :class=" secord_date == 1 ? 'jp-bottom-danpin-head-right-item jp-bottom-danpin-head-right-item-choose' : 'jp-bottom-danpin-head-right-item' ">
              7日
            </div>
            <el-divider direction="vertical" style="color: #E7E7E7;"></el-divider>
            <div @click="changRankTime(2,2)" :class=" secord_date == 2 ? 'jp-bottom-danpin-head-right-item jp-bottom-danpin-head-right-item-choose' : 'jp-bottom-danpin-head-right-item' ">
              30日
            </div>
          </div>
        </div>
        <div :class="(secord_list && secord_list.length > 0) ? 'jp-bottom-danpin-content'  : 'jp-bottom-danpin-content jp-bottom-danpin-content-center '">
          <div v-if="secord_list && secord_list.length > 0">
            <div v-for="(item,index) in secord_list " :key="index" class="jp-bottom-danpin-content-list" @click="tzxqa(item.productId)">
              <div class="jp-bottom-danpin-content-item">
                <div class="jp-bottom-danpin-content-item-img">
                  <img :src=" type == 1 ? ( secord_data.imageUrl || require('../../assets/imgs/empty.png')) : ( item.imageUrl || require('../../assets/imgs/empty.png'))" class="jp-bottom-danpin-content-item-img-main" alt="">
                  <img v-if="index==0" class="jp-bottom-danpin-content-item-img-price" src="../../assets/images/Slice16.png" alt="">
                  <img v-if="index==1" class="jp-bottom-danpin-content-item-img-price" src="../../assets/images/Slice17.png" alt="">
                  <img v-if="index==2" class="jp-bottom-danpin-content-item-img-price" src="../../assets/images/Slice18.png" alt="">
                </div>
                <div class="jp-bottom-danpin-content-item-title font-14">
                  {{ (type == 1 ?  item.keyword : item.productName) || ''}}
                </div>
                <div class="jp-bottom-danpin-content-item-sell">
                  <div class="jp-bottom-danpin-content-item-sell-title font-12">
                    {{  type == 1 ? '交易指数' : '销量' }}
                  </div>
                  <div class="jp-bottom-danpin-content-item-sell-num font-18">
                    {{  (type == 1 ? item.dsr : item.sales) || 0 | formatMoney}}
                  </div>
                </div>
              </div>
              <el-divider></el-divider>
            </div>
          </div>

          <div v-else>
            <el-empty description="暂无数据"></el-empty>
          </div>
        </div>
      </div>
    </div>
    <!-- 选项弹窗 -->
    <el-dialog :title="dialog_title" :visible.sync="select_show" width="50%" @close="select_show = false">
      <div v-if="type == 1">
        <el-table border ref="singleTable" class="abc" :header-cell-style="header_cell_stle" :data="tableData" highlight-current-row @current-change="handleCurrentChange">
          <el-table-column label="竞品名称" align="center">
            <template slot-scope="scope">
              <div style="display: flex;text-align: left;align-items:center">
                <div style="width: 77px;height:77px;margin-right: 21px;">
                  <img style="width: 77px;height:77px;border-radius: 10px;" :src="scope.row.imageUrl || require('../../assets/imgs/empty.png')" alt="">
                </div>
                <div style="width:600px;">
                  <el-popover placement="top-start" width="350" trigger="hover" :content="scope.row.name">
                    <span slot="reference" class="font-12" style="line-height: 15px; color:#333;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">{{ scope.row.name }}</span>
                  </el-popover>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="商品销售量" width="150" align="center">
            <template slot-scope="scope">
              <div style="width: 100%;">
                {{   scope.row.sales >= 100000 ? '10w+' : scope.row.sales}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="销售额" width="150" align="center">
            <template slot-scope="scope">
              <div style="width: 100%;">
                {{  ( scope.row.salesAmount >= 100000 ? '10w+' : scope.row.salesAmount) | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="评分" width="100" align="center">
            <template slot-scope="scope">
              {{ scope.row.score ? scope.row.score  : '--' }}
            </template>
          </el-table-column>
          <el-table-column label="客单价" width="100" align="center">
            <template slot-scope="scope">
              {{scope.row.currencySymbol}}{{scope.row.price}}
            </template>
          </el-table-column>
        </el-table>
        <div style="margin: 10px auto;text-align: center;">
          <el-pagination @current-change="changePage" :current-page="page" :page-size="page_size" layout="total, prev, pager, next, jumper" :total="tableData.length">
          </el-pagination>
        </div>
      </div>
      <div v-else>
        <el-table class="abc" border ref="singleTable" :header-cell-style="header_cell_stle" :data="tableData" highlight-current-row @current-change="handleCurrentChange">
          <el-table-column label="竞店名称" align="center">
            <template slot-scope="scope">
              <div style="display: flex;text-align: left;padding: 11px 0;align-items:center">
                <div style="width: 77px;height:77px;margin-right: 21px;">
                  <img style="width: 77px;height:77px;border-radius: 10px;" :src="scope.row.cover" alt="">
                </div>
                <div style="width: 420px;">
                  <div style="color:#013bfe;">
                    <span class="font-12" style="line-height: 15px; color:#333;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">{{ scope.row.title }}</span>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="销售量" prop="shopSold" align="center"></el-table-column>
          <el-table-column label="销售额" prop="shopSoldAmount" align="center"></el-table-column>
          <el-table-column label="评分" align="center">
            <template slot-scope="scope">
              {{ scope.row.score ? scope.row.score  : '--' }}
            </template>
          </el-table-column>
          <el-table-column label="客单价" align="center">
            <template slot-scope="scope">
              <div style="width: 100%;text-align: center;">
                <div style="color:#f5762c;">{{scope.row.avg }} </div>
                <div class="font-10">{{scope.row.chAvg }}</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin: 10px auto;text-align: center;">
          <el-pagination @current-change="changePage" :current-page="page" :page-size="page_size" layout="total, prev, pager, next, jumper" :total="tableData.length">
          </el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="display:flex;justify-content:right">
        <div class="font-14" style="margin-right: 25px;cursor: pointer; background-color:rgba(51, 51, 51, 0.096); border: 1px solid rgba(51, 51, 51, 0.459);color: #333;border-radius: 5px;padding: 3px 7px;" @click="select_show = false">取 消</div>
        <div class="font-14" style="cursor: pointer; background-color:#4974e917;border: 1px solid #4974e97a;color: #4975E9;border-radius: 5px;padding: 3px 7px;" @click="confirmChangeItem">确 定</div>

      </span>
    </el-dialog>
    <el-dialog :visible.sync="show_vip" width="20%" custom-class="dialogwidth">
      <div style="text-align:center;align-items:center;min-width:300px">
        <div>您还未开通会员，请联系客服开通</div>
        <img src="../../assets/imgs/kf_qr.png" style="width:220px;height:220px" alt="">
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="show_vip = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<!-- 定义JS变量 -->
<script>
import { getCollectProduct, listCollectShop, getFlowListTopTen, getCompareSaleTrendList, getCompareShopTrend, getTopSalesList } from '../../api/api'
import compare from './components/compare.vue'
export default {
  data() {
    return {
      header_cell_stle: { 'font-siz': '14px', 'color': '#333', 'background': '#F7F8FA', 'font-weight': 'normal' },//弹窗标题头样式
      pagetitle: '',//标题
      type: 1,// 类型 1 竞品 2竞店
      form_date_type: 1,//图片的日期选择
      my_data: {}, //我的店铺  我的商品
      first_data: {}, //竞品1数据
      secord_data: {}, // 竞品2数据
      change_type: 0, // 选中要更改的数据序号 0 我的商品 1 竞品1 2 竞品2
      page: 1, //分页
      page_size: 5, //单页个数
      user: {}, //用户信息
      my_date: 0,//我的商品日期选项 0 昨日 1 7天内 2 30天内
      first_date: 0,//竞品1日期选项  0 昨日 1 7天内 2 30天内
      secord_date: 0, //竞品2日期选项  0 昨日 1 7天内 2 30天内
      form_data: {
        my_form_list: [],//我的商品趋势 
        first_form_list: [],//竞品1趋势 
        secord_form_list: [],//竞品2趋势 
      },
      my_list: [],//我的商品排行榜 
      first_list: [],//竞品1排行榜
      secord_list: [],//竞品2排行榜


      select_show: false, //选择竞品弹窗
      dialog_title: '',//弹窗标题
      tableData: [],//我的收藏
      currentRow: [],//当前选中项
      loading: false,
      show_vip: false,
    }
  },
  created() {
    let u = localStorage.getItem('user');
    this.user = JSON.parse(u);
  },
  components: {
    compare
  },
  computed: {
    is_vip() {
      return this.user && this.user.level && this.user.level > 10
    },
    is_svip() {
      return this.user && this.user.level && this.user.level > 20
    }
  },
  methods: {
    init() {
      console.log('init')
      this.my_data = {};
      this.first_data = {};
      this.secord_data = {};
      this.my_date = 0;
      this.first_date = 0;
      this.secord_date = 0;
      this.form_data = {
        my_form_list: [],//我的商品趋势 
        first_form_list: [],//竞品1趋势 
        secord_form_list: [],//竞品2趋势 
      };
      this.my_list = [];
      this.first_list = [];
      this.secord_list = [];
    },
    //修改竞品排行榜时间 num 0 我的店铺的商品排行的日期修改 1 竞品1排行的日期修改 2 竞品2排行的日期修改  date_type 修改的日期
    changRankTime(num, date_type) {
      switch(num) {
        case 0:
          this.my_date = date_type
          break;
        case 1:
          this.first_date = date_type
          break;
        case 2:
          this.secord_date = date_type
          break;
      }
      this.updateList(num, this.type)

    },
    //删除数据
    deleteItem(data) {
      switch(data) {
        case 0:
          this.my_data = Object.assign({});
          this.my_list = [];
          this.form_data = {
            ...this.form_data,
            my_form_list: [],
          }
          break;
        case 1:
          this.first_data = Object.assign({});
          this.first_list = [];
          this.form_data = {
            ...this.form_data,
            first_form_list: [],
          }
          break;
        case 2:
          this.secord_data = Object.assign({});
          this.secord_data = Object.assign({});
          this.secord_list = [];
          this.form_data = {
            ...this.form_data,
            secord_form_list: [],
          }
          break;
      }
      console.log('deleteItem', this.form_data);
      this.$refs['compare'].loadLine();
    },
    // 确认选中  我的商品/竞品1/竞品2
    confirmChangeItem() {
      if(this.currentRow && this.currentRow.id) {
        switch(this.change_type) {
          case 0:
            this.my_data = Object.assign({}, this.currentRow);
            break;
          case 1:
            this.first_data = Object.assign({}, this.currentRow);
            break;
          case 2:
            this.secord_data = Object.assign({}, this.currentRow);
            break;
        }
        this.updateForm(this.change_type, this.type);
        this.updateList(this.change_type, this.type);
      }

      this.$nextTick(() => {
        this.select_show = false;
      })
    },
    //改变图标日期选择
    changeFormDate(data) {
      this.form_date_type = data;
      let change_list = [];
      if(this.my_data && this.my_data.id && this.my_data.id > 0) {
        change_list.push(0)
      }
      if(this.first_data && this.first_data.id && this.first_data.id > 0) {
        change_list.push(1)
      }
      if(this.secord_data && this.secord_data.id && this.secord_data.id > 0) {
        change_list.push(2)
      }
      this.updateCompare(change_list, this.type);
    },
    //更新数据
    updateCompare(list, type) {
      list.map(v => {
        this.updateForm(v, type);
      })
    },
    //更新销售量数据
    async updateForm(num, type) {
      this.loading = true;
      let param = {}
      let data = {};
      let key = ''
      switch(num) {
        case 0:
          data = Object.assign({}, this.my_data);
          break;
        case 1:
          data = Object.assign({}, this.first_data);
          break;
        case 2:
          data = Object.assign({}, this.secord_data);
          break;
      }
      param['dateType'] = this.form_date_type
      if(type == 1) {
        param['goodsId'] = data.goodsId
        const { data: res } = await getCompareSaleTrendList(param)
        console.log('updateForm', res)

        switch(num) {
          case 0:
            this.form_data = {
              ...this.form_data,
              my_form_list: res.data,
            }
            break;
          case 1:
            this.form_data = {
              ...this.form_data,
              first_form_list: res.data,
            }
            break;
          case 2:
            this.form_data = {
              ...this.form_data,
              secord_form_list: res.data,
            }
            break;
        }
      } else {
        param['shopId'] = data.shopId
        const { data: res } = await getCompareShopTrend(param)
        console.log('updateForm', res)
        switch(num) {
          case 0:
            this.form_data = {
              ...this.form_data,
              my_form_list: res.data,
            }
            break;
          case 1:
            this.form_data = {
              ...this.form_data,
              first_form_list: res.data,
            }
            break;
          case 2:
            this.form_data = {
              ...this.form_data,
              secord_form_list: res.data,
            }
            break;
        }
      }
      this.loading = false;
    },
    //更新排行数据
    async updateList(num, type) {
      this.loading = true;
      let param = {}
      let data = {};
      let date_type = 0
      switch(num) {
        case 0:
          data = Object.assign({}, this.my_data);
          date_type = this.my_date
          break;
        case 1:
          data = Object.assign({}, this.first_data);
          date_type = this.first_date
          break;
        case 2:
          data = Object.assign({}, this.secord_data);
          date_type = this.secord_date
          break;
      }
      param['dateType'] = date_type
      if(type == 1) {
        param['id'] = data.id
        const { data: res } = await getFlowListTopTen(param)
        console.log('updateList', res)
        switch(num) {
          case 0:
            this.my_list = [].concat(res.data);
            break;
          case 1:
            this.first_list = [].concat(res.data);
            break;
          case 2:
            this.secord_list = [].concat(res.data);
            break;
        }
      } else {
        param['shopId'] = data.shopId
        const { data: res } = await getTopSalesList(param)
        console.log('updateList', res)
        switch(num) {
          case 0:
            this.my_list = [].concat(res.data);
            break;
          case 1:
            this.first_list = [].concat(res.data);
            break;
          case 2:
            this.secord_list = [].concat(res.data);
            break;
        }
      }
      this.loading = false;
    },
    handleCurrentChange(val) {
      this.currentRow = val;
      console.log('handleCurrentChange', val)
    },
    changePage(val) {
      this.page = val;
      console.log('changePage', val)
      this.this.$nextTick(() => {
        if(this.type == 1) {
          this.getCollect();
        } else {
          this.getShopList();
        }
      })
    },
    //点击了 我的商品 /竞品1 / 竞品2
    changeSelect(data) {
      if(!(this.user && this.user.id)) {
        this.$confirm('用户还未登录, 是否前往登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$router.push("/login")
        }).catch(() => {
        });
        return;
      }
      if(!(this.is_svip || this.is_vip)) {
        this.show_vip = true;
        return;
      }
      this.change_type = data;
      this.select_show = true;
      this.currentRow = null;
      console.log('user', this.user);
      this.dialog_title = '';
      this.page = 1;
      if(this.type == 1) {
        switch(data) {
          case 0:
            this.dialog_title = '竞品1'
            break;
          case 1:
            this.dialog_title = '竞品2'
            break;
          case 2:
            this.dialog_title = '竞品3'
            break;
        }
        this.getCollect()
      } else {
        switch(data) {
          case 0:
            this.dialog_title = '竞店1'
            break;
          case 1:
            this.dialog_title = '竞店2'
            break;
          case 2:
            this.dialog_title = '竞店3'
            break;
        }
        this.getShopList()
      }
    },
    //获取收藏的商品
    async getCollect() {
      let param = {}
      param['page'] = this.page;
      param['pageSize'] = this.page_size;
      param['userId'] = this.user && this.user.id ? this.user.id : 0;
      const { data: res } = await getCollectProduct(param);
      console.log('data', res.data);
      const { records } = res.data;
      this.tableData = records;
    },
    //获取收藏的店铺
    async getShopList() {
      let param = {}
      param['page'] = this.page;
      param['pageSize'] = this.page_size;
      param['userId'] = this.user && this.user.id ? this.user.id : 0;
      const { data: res } = await listCollectShop(param);
      console.log('data', res.data);
      const { records } = res.data;
      this.tableData = records;
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query;
        this.type = query.type && query.type > 0 ? query.type : 1;
        if(this.type == 1) {
          this.pagetitle = '竞品分析'
        } else {
          this.pagetitle = '竞店分析'
        }
        this.init();
      },
      immediate: true,
    },
  },

}
</script>

<!-- 防止组件冲突 -->
<style lang="less" scoped>
.abc {
  /deep/.el-table__body tr.current-row > td {
    color: #333;
    background: #4974e91f !important;
  }
}
/deep/ .dialogwidth {
  min-width: 300px;
}
.jp {
  width: calc(100% - 154px);
  min-height: 100vh;
  background-color: #fff;
  padding: 17px;
  &-top {
    background: #fff;
    padding: 16px;
  }
  &-title {
    display: flex;
    align-items: center;
    &-color {
      display: inline-block;
      width: 4px;
      background-color: #4975e9;
      height: 18px;
      margin-right: 5px;
    }
    &-page {
      font-weight: bold;
      //   font-size: 20px;
      line-height: 23px;
      font-family: PingFang SC-Bold;
      color: #333;
    }
  }
  &-choose {
    margin-top: 19px;
    height: 100px;
    display: flex;
    justify-content: center;
    &-title {
      width: 200px;
      padding: 37px 0 37px 0;
      text-align: center;
      justify-items: center;
      margin-left: 9px;
      font-family: PingFang SC-Medium;
    }
    &-item {
      width: 288px;
      border-radius: 5px;
      border: 1px solid #dcdcdc;
      display: flex;
      &-choose {
        display: flex;
        padding: 12px 0px 13px 12px;
        width: 100%;
        position: relative;
        /deep/.el-badge__content.is-fixed {
          right: 30px;
        }
        &-pic {
          width: 75px;
          height: 75px;
          position: relative;
          border-radius: 14px;
        }
        &-title {
          margin: 15px 0 16px 14px;
          //   font-size: 12px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          /* 设置文本超出部分省略 */
          text-overflow: ellipsis;
        }
        &-close {
          position: absolute;
          right: 3px;
          top: 3px;
          cursor: pointer;
          border: 2px dotted #dcdcdc;
        }
      }
      &-empty {
        cursor: pointer;
        text-align: center;
        line-height: 100px;
        width: 288px;
      }
    }
    &-contain {
      margin: 35px 16px;
      &-vs {
        width: 37px;
        height: 30px;
      }
    }
  }
  &-gap {
    padding: 0 1px;
    /deep/.el-divider--horizontal {
      margin: 22px 1px 15px 1px;
      color: #e7e7e7;
    }
  }
  &-data {
    &-head {
      display: flex;
      justify-content: space-between;
      &-title {
        text-align: center;
        margin-left: 9px;
        // font-size: 18px;
        float: left;
      }
      &-date {
        float: right;
        display: flex;
        color: #767676;
        cursor: pointer;
        &-choose {
          color: #4975e9;
        }
      }
    }
    &-content {
      &-items {
        display: flex;
        margin-top: 22px;
        &-item {
          position: relative;
          width: 390px;
          height: 165px;
          padding: 28px;
          display: flex;
          flex-wrap: wrap;
          border-radius: 5px;
          border: 1px solid #dcdcdc;
          &-jiaobiao {
            position: absolute;
            top: 0;
            left: 0;
          }
          &-part {
            display: flex;
            flex: 0 0 50%;
            &-right {
              text-align: left;
              margin-left: 6px;
              &-title {
                color: #767676;
              }
              &-num {
                margin-top: 3px;
                color: #333333;
              }
            }
          }
        }
      }
    }
    &-contain {
      margin: 74px 27px;
      &-vs {
        width: 37px;
        height: 30px;
      }
    }
    &-charts {
      margin-top: 20px;
      height: 230px;
    }
  }
  &-bottom {
    margin-top: 16px;
    display: flex;
    &-danpin {
      padding: 16px;
      background: #ffffff;
      width: 692px;
      margin-left: 16px;
      &-head {
        justify-content: space-between;
        display: flex;
        &-left {
          display: flex;
          align-items: center;
          &-color {
            display: inline-block;
            width: 4px;
            background-color: #4975e9;
            height: 18px;
            margin-right: 5px;
          }
          &-page {
            font-weight: bold;
            // font-size: 20px;
            line-height: 23px;
            font-family: PingFang SC-Bold;
            color: #333;
          }
        }
        &-right {
          display: flex;
          //   font-size: 12px;
          color: #767676;
          align-items: center;
          &-item {
            cursor: pointer;
            &-choose {
              color: #4975e9;
            }
          }
        }
      }
      &-content {
        margin-top: 16px;
        &-center {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        width: 100%;
        min-height: 700px;
        &-list {
          /deep/ .el-divider--horizontal {
            margin: 12px 0;
          }
        }

        &-item {
          display: flex;
          color: #333333;
          height: 60px;
          align-items: center;
          justify-content: center;
          &-img {
            margin-left: 16px;
            position: relative;
            &-main {
              height: 60px;
              width: 60px;
              border-radius: 10px;
              box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
            }
            &-price {
              width: 19px;
              height: 21px;
              position: absolute;
              top: -4px;
              left: -4px;
            }
          }
          &-title {
            margin-left: 14px;
            // font-size: 16px;
            line-height: 19px;
            width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            line-height: 16px; /* 这里的行高要和你的字体大小相匹配 */
            max-height: 48px; /* 3行行高的和 */
            position: relative;
            text-overflow: ellipsis;
          }
          &-sell {
            text-align: right;
            margin-right: 14px;
            margin-left: 14px;
            &-title {
              //   font-size: 14px;
              min-width: 80px;
            }
            &-num {
              margin-top: 4px;
              min-width: 28px;
            }
          }
        }
      }
    }
  }
}
</style>
