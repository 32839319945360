<template>
  <div>

    <div id="jp_compare" class="chart" :style="{width: '1300', height: '230px','min-width':'500px'}"></div>
  </div>
</template>
   
   <script>
import * as echarts from 'echarts';
export default {
  name: 'xwPassengerFlow',
  props: {
    bingtuData: {
      type: Object,
      default() {
        return {
          my_form_list: [],//我的商品趋势 
          first_form_list: [],//竞品1趋势 
          secord_form_list: [],//竞品2趋势 
        };
      }
    },
    type: {
      type: [Number, String],
      default() {
        return 1;
      }
    }
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.loadLine()
  },
  watch: {
    bingtuData(val) {
      console.log('watch', val)
      this.loadLine();
    }
  },

  methods: {
    formatMoney(money, t) {
      let result = money;
      if(money) {
        var parts = money.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if(parts.length > 1) {
          result = parts.join(".")
        } else {
          result = parts[0]
        }
      }
      return result;
    },
    loadLine() {
      var legend_list = [];
      var series_list = [];
      var xAxis_list = [];
      var my_data_list = [];
      var first_data_list = [];
      var secord_data_list = [];
      var my_data_show_list = [];
      var first_data_show_list = [];
      var secord_data_show_list = []
      const { my_form_list, first_form_list, secord_form_list } = this.bingtuData;
      console.log('this.bingtuData', this.bingtuData)
      if(my_form_list && my_form_list.length > 0) {
        my_form_list.map(item => {
          xAxis_list.push(item.date);
          var d = this.formatMoney(item.value)
          my_data_list.push(item.value);
          my_data_show_list.push(d);
        })
        if(this.type == 1) {
          legend_list.push('竞品1');
          series_list.push({
            name: '竞品1',
            type: 'line',
            data: my_data_list,
          })
        } else {
          legend_list.push('竞店1');
          series_list.push({
            name: '竞店1',
            type: 'line',
            data: my_data_list,
          })
        }
      }
      if(first_form_list && first_form_list.length > 0) {
        let push_date = xAxis_list.length == 0 ? true : false;
        first_form_list.map(item => {
          if(push_date) {
            xAxis_list.push(item.date);
          }
          var d = this.formatMoney(item.value)
          first_data_list.push(item.value);
          first_data_show_list.push(d);
        })
        if(this.type == 1) {
          legend_list.push('竞品2');
          series_list.push(
            {
              name: '竞品2',
              type: 'line',
              data: first_data_list,
            },
          )
        } else {
          legend_list.push('竞店2');
          series_list.push(
            {
              name: '竞店2',
              type: 'line',
              data: first_data_list,
            },
          )
        }
      }
      if(secord_form_list && secord_form_list.length > 0) {
        let push_date = xAxis_list.length == 0 ? true : false;
        secord_form_list.map(item => {
          if(push_date) {
            xAxis_list.push(item.date);
          }
          var d = this.formatMoney(item.value)
          secord_data_list.push(item.value);
          secord_data_show_list.push(d);
        })
        if(this.type == 1) {
          legend_list.push('竞品3');
          series_list.push(
            {
              name: '竞品3',
              type: 'line',
              data: secord_data_list,
            },
          )
        } else {
          legend_list.push('竞店3');
          series_list.push(
            {
              name: '竞店3',
              type: 'line',
              data: secord_data_list,
            },
          )
        }
      }
      console.log('draw', series_list, legend_list, xAxis_list);
      if(series_list.length == 0 || legend_list.length == 0) {
        var myChart = echarts.init(document.getElementById('jp_compare'));
        myChart.dispose(); // 清空图表
        return;
      }
      var option = {
        title: {
        },
        tooltip: {
          trigger: 'axis',
          showContent: true,
          backgroundColor: 'transparent',
          padding: 0,
          // 提示框浮层内容格式器: 自定义提示框样式
          formatter: function(params) { // params 是 formatter 需要的数据集
            let content = "";
            params.forEach((item) => {
              // 提示框的内容样式及数据
              let first = ''
              let end = ''
              let part = ''
              if(item.seriesName == "竞品1" || item.seriesName == "竞店1") {
                first = '<div style="width: 100%; background: rgba(255,255,255,0.9);padding: 10px 8px;border-radius:4px">'
                end = `<span style="color: #424864;">${my_data_show_list[item.dataIndex]}</span>
        </div>`
              } else if(item.seriesName == "竞品2" || item.seriesName == "竞店2") {
                first = '<div style="width: 100%;  background: rgba(255,255,255,0.9);padding: 10px 8px;margin-top:4px;border-radius:4px">'
                end = `<span style="color: #424864;">${first_data_show_list[item.dataIndex]}</span>
        </div>`
              } else if(item.seriesName == "竞品3" || item.seriesName == "竞店3") {
                first = '<div style="width: 100%;  background: rgba(255,255,255,0.9);padding: 10px 8px;margin-top:4px;border-radius:4px">'
                end = `<span style="color: #424864;">${secord_data_show_list[item.dataIndex]}</span>
        </div>`
              }
              part = `
          <span style="display: inline-block; margin-right: 10px; border-radius: 50%; width: 9px; height: 9px;background-color: ${item.color};"></span>
          <span style="color: #424864;">${item.seriesName}:</span>`
              content += first + part + end;
            });
            // 提示框外框样式及内容数据
            const htmlContent = `<div style="width: 100%; height: 100%;border-radius: 6px 6px 6px 6px;background: linear-gradient( 321deg, #FDFEFF 0%, #F4F7FC 100%);padding:7px 8px 8px 7px">
            <div style="font-size:11px;margin-left:6px;height:19px;color:#1D2129;font-weight:bold;margin-bottom:4px">   
        ${params[0].name}   
         </div>
          ${content}
          `;

            return htmlContent;
          },
        },
        legend: {
          data: legend_list
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxis_list
        },
        yAxis: {
          type: 'value'
        },
        series: series_list
      };
      this.myChartOne = echarts.init(document.getElementById('jp_compare'))
      this.myChartOne.showLoading();
      this.$nextTick(() => {
        this.myChartOne.hideLoading();
        this.myChartOne.setOption(option, true)
      })
    }
  }
}
   </script>

